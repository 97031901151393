<template>
  <div v-if="preview">
    {{ previewTitle }}
  </div>
  <div
    v-else
    :class="[
      'relative h-full w-full group/cell',
      { '!cursor-not-allowed': disabled },
    ]"
  >
    <UPopover
      v-model:open="open"
      :ui="{
        width: 'min-w-52 max-w-[280px]',
        wrapper: 'h-full',
        trigger: `h-full flex items-center ${
          disabled ? '!cursor-not-allowed' : ''
        }`,
      }"
      :popper="{
        placement: 'bottom-start',
        strategy: 'fixed',
      }"
      :disabled="disabled"
      v-bind="$attrs"
    >
      <template #panel>
        <div class="flex items-center justify-between pt-3 pb-2 px-4">
          <p class="font-semibold text-sm text-gray-900">{{ label }}</p>
          <UButton
            size="xs"
            icon="i-heroicons-x-mark"
            color="gray"
            variant="ghost"
            @click.prevent.stop="open = false"
          />
        </div>
        <div class="px-4">
          <UInput
            v-model="search"
            icon="i-heroicons-magnifying-glass"
            size="sm"
            autofocus
            placeholder="Search option"
            :ui="{
              base: '!rounded-md',
            }"
          />
        </div>
        <div
          class="max-h-[21.375rem] overflow-y-auto scroll-stable minimal-scrollbar mt-2 pl-2 pr-0.5 pb-2 space-y-0.5"
        >
          <div
            v-for="(option, index) in filteredMenuOptions"
            :key="option.value"
            :class="[
              'flex items-center text-gray-900 justify-between px-2 py-1.5 cursor-pointer rounded-md gap-1.5 hover:bg-gray-100',
              {
                'bg-gray-100':
                  option.value === selected || currentFocusOption === index,
              },
            ]"
            @mouseenter="currentFocusOption = index"
            @mouseleave="currentFocusOption = -1"
            @click="onSelect(option.value)"
          >
            <Tooltip :text="option.label" arrow-class="!top-[unset]">
              <template #default="{ getTextRef }">
                <div
                  :class="[
                    'px-2 py-1 rounded-md',
                    { '!cursor-not-allowed': disabled },
                  ]"
                  :style="getFieldOptionPreset(option.color)"
                >
                  <span
                    :ref="getTextRef"
                    class="text-sm line-clamp-1 break-all font-medium"
                  >
                    {{ option.label }}
                  </span>
                </div>
              </template>
            </Tooltip>
            <Icon
              v-if="option.value === selected"
              name="heroicons:check"
              :size="20"
              class="min-w-5"
            />
          </div>
        </div>
      </template>
      <div class="pl-2 pr-8 flex items-center">
        <div
          v-if="selectedOption?.label"
          class="px-2 py-1 rounded"
          :style="getFieldOptionPreset(selectedOption?.color!)"
        >
          <Tooltip :text="selectedOption?.label" class="flex">
            <template #default="{ getTextRef }">
              <div
                :ref="getTextRef"
                class="line-clamp-1 break-all leading-4 text-xs"
              >
                {{ selectedOption?.label }}
              </div>
            </template>
          </Tooltip>
        </div>
        <div
          v-else
          class="leading-4 text-xs group-hover/cell:visible invisible"
        >
          -
        </div>
      </div>
    </UPopover>
    <div class="absolute right-1 top-1/2 -translate-y-1/2 flex items-center">
      <UButton
        v-if="selected && !disabled"
        color="gray"
        variant="ghost"
        size="2xs"
        icon="i-heroicons-x-mark"
        class="group-hover/cell:visible invisible"
        @click.prevent.stop="onClear"
      />
      <Icon
        v-else
        name="heroicons:chevron-down"
        class="text-gray-500 group-hover/cell:visible invisible mr-1"
        size="2xs"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { FieldOption } from '#field/types'

const props = defineProps<{
  value: string | undefined
  description?: string
  label?: string
  options: string
  preview?: boolean
  fieldId?: string
  disabled?: boolean
}>()

const emit = defineEmits(['change', 'set-callback'])

const currentFocusOption = ref(-1)
const selected = ref(props.value)
const open = ref(false)
const search = ref('')

const menuOptions = computed<Array<FieldOption>>(() => {
  return (safeParseStringToJSON(props.options, []) as FieldOption[]).filter(
    (option) => option.label
  )
})

const filteredMenuOptions = computed(() => {
  return menuOptions.value.filter((option) => {
    return option.label.toLowerCase().includes(search.value.toLowerCase())
  })
})

const selectedOption = computed(() => {
  return menuOptions.value.find((option) => option.value === selected.value)
})

const previewTitle = computed(() => {
  return selectedOption.value?.label ? selectedOption.value.label : '-'
})

const onClear = () => {
  selected.value = ''
  emit('change', '', props.fieldId)
  open.value = false
}

const onSelect = (value: string) => {
  if (value != props.value) {
    emit('change', value, props.fieldId)
    open.value = false
  }
}

defineExpose({
  focus: () => {
    open.value = true
  },
})

onMounted(() => {
  emit('set-callback', {
    open: () => {
      open.value = true
    },
  })
})

watch(
  () => props.value,
  (val) => {
    selected.value = val
  }
)

defineShortcuts({
  arrowup: {
    whenever: [open],
    usingInput: true,
    handler: () => {
      if (currentFocusOption.value > 0) {
        currentFocusOption.value--
        return
      }

      currentFocusOption.value = filteredMenuOptions.value.length - 1
    },
  },
  arrowdown: {
    whenever: [open],
    usingInput: true,
    handler: () => {
      if (currentFocusOption.value < filteredMenuOptions.value.length - 1) {
        currentFocusOption.value++
        return
      }

      currentFocusOption.value = 0
    },
  },
  enter: {
    whenever: [open],
    usingInput: true,
    handler: () => {
      if (
        currentFocusOption.value >= 0 &&
        filteredMenuOptions.value[currentFocusOption.value]
      ) {
        onSelect(filteredMenuOptions.value[currentFocusOption.value].value)
      }
    },
  },
})
</script>
